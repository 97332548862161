/* Poppins font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@800&family=Poppins&family=Roboto&family=Ubuntu&display=swap");

.App {
  font-family: "Poppins", sans-serif;
}

.navbar {
  background-color: #a7defc;
}

.section {
  padding-top: 80px;
}

.section2 {
  padding-top: 30px;
}

.heading {
  font-size: 20px;
  font-weight: bold;
}

.subheading {
  font-size: 16px;
  font-weight: bold;
  color: #818589;
}

.alt-text {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-style: italic;
}

.parent {
  padding-bottom: 10px;
}

#restaurant-img {
  width: 50%;
  height: 50%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

#desktop-sc {
  width: 100%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

#tablet-mobile-sc {
  width: 50%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

#desktop-lf {
  width: 70%;
  margin-top: 12px;
}

#tablet-lf {
  width: 80%;
  margin-top: 12px;
}

#mobile-lf {
  width: 70%;
  margin-top: 12px;
}

#style-guide {
  width: 60%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

#desktop-hf {
  width: 70%;
  margin-top: 12px;
}

#tablet-hf {
  width: 80%;
  margin-top: 12px;
}

#mobile-hf {
  width: 50%;
  margin-top: 12px;
}

#redesign-desktop-sc {
  width: 70%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

#redesign-tablet-sc {
  width: 80%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

#redesign-mobile-sc {
  width: 54%;
  border: solid;
  border-color: black;
  border-width: 3px;
  margin-top: 12px;
}

p {
  margin: 0px 0px 0px 0px;
}

.card-col {
  display: flex;
  margin-bottom: 24px;
}

.card {
  align-items: stretch;
  width: 100%;
}

.line {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}
